<template>
	<b-row>
		<b-col cols="12">
			<b-card no-body class="mb-4">
				<b-card-body class="p-3">
					<b-tabs content-class="mt-3" align="left">
						<!-- Section Main -->
						<b-tab title="Main" active>
							<Main />
						</b-tab>
						<!-- End Section -->

						<!-- Aditional Info Section -->
						<b-tab title="Additional Details">
							<AditionalDetails />
						</b-tab>
						<!-- End Section -->

						<!-- Scan Section -->
						<b-tab title="Documents">
							<ScannedDocuments />
						</b-tab>
						<!-- End Section -->

						<!-- Scan Section -->
						<b-tab title="Comments">
							<Comments />
						</b-tab>
						<!-- End Section -->

						<!-- Scan Section -->
						<b-tab title="Employment">
							<Employment />
						</b-tab>
						<!-- End Section -->
					</b-tabs>
				</b-card-body>
			</b-card>
		</b-col>
	</b-row>
</template>

<script>
import Main from "@/components/customers/Main.vue";
import AditionalDetails from "@/components/customers/AditionalDetails.vue";
import ScannedDocuments from "@/components/customers/ScannedDocuments.vue";
import Comments from "@/components/customers/Comments.vue";
import Employment from "@/components/customers/Employment.vue";
export default {
	name: "Customer",
	components: {
		Main,
		AditionalDetails,
		ScannedDocuments,
		Comments,
		Employment,
		// Membercheck,
		// Screening,
	},
	data: () => ({
		loading: false,
	}),
	methods: {
		async getData() {
			this.loading = true;
			await this.$store.dispatch("customers/get_customer", this.$route.params.customer);
			await this.$store.dispatch("productRequest/get_processResquest", this.$route.params.customer);
			this.loading = false;
		},
	},
	created() {
		this.getData();
	},
};
</script>
